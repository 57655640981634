import React, {Component} from 'react';

import Main from "./sections/Main";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Services from "./sections/Services";
import Message3 from "./sections/Message3";
import Portfolio from "./sections/Portfolio";
import Footer from "./sections/Footer";
import Navigation from "./components/Navigation";
import Message1 from './sections/Message1';
import Message2 from './sections/Message2';
import Message4 from './sections/Message4';


class App extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className="App">
       
        <Navigation />
        <Main />
        <Message1 />
        <About />
        <Message3 />
        <Services />  
        <Message4 />         
        <Portfolio />
        <Message2 />
        <Contact />
        <Footer />
      </div>  
        
    );
  }
}

export default App;
