import React, { Component } from 'react';
import {Link, animateScroll as scroll } from "react-scroll";
import logo from './images-components/xegotech-hlogo.png';
import './navigation.css';

class Navigation extends Component {
    state = {};
    scrollToTop = () => {
        scroll.scrollToTop(); 
    };
    render() {
        return (
            <div className="nav-bar">
                <img src={logo} 
                    alt="Logo" 
                    className='nav_logo'
                    onClick={this.scrollToTop}
                    />
                <Link
                    className="nav-links"
                    activeClass="active"
                    to="main"
                    spy={true}
                    smooth={true}
                    offset={-0}
                    duration={500}
                    >MAIN</Link>
                
                <Link
                    className="nav-links"
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-0}
                    duration={500}
                    >ABOUT</Link>
                
                <Link
                    className="nav-links"
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-0}
                    duration={500}
                    >SERVICES</Link>
                
                <Link
                    className="nav-links"
                    activeClass="active"
                    to="portfolio"
                    spy={true}
                    smooth={true}
                    offset={-0}
                    duration={500}
                    >PORTFOLIO</Link>

                <Link
                    className="nav-links-last"
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-0}
                    duration={500}
                    >CONTACT</Link>

              

                
             </div>   
                
                
               
        )
    }
}



export default Navigation;
