import React, { Component } from 'react'
import './message1.css';


export default class Marketing extends Component {
    render() {
        return (
            <div className="message1_section" id="marketing">
                <div className="message1_content_container">  
                    <div className="message1_first_line">Does your brand leave a</div>
                    <div className="message1_second_line">LASTING</div>
                    <div className="message1_third_line">impression on anyone?</div>
                    
                </div>                
            </div>
        )
    }
}
