import React, { Component } from 'react';
import servicesBgImage from './images-sections/backgrounds/bg-c.jpg';
import './services.css';
import './styles-common.css';
import iconImage01 from './images-sections/icons/brand-icon.png';
import iconImage02 from './images-sections/icons/startup-adv-icon.png';
import iconImage03 from './images-sections/icons/web-dev-icon.png';
import iconImage04 from './images-sections/icons/dig-marketing-icon.png';
import iconImage05 from './images-sections/icons/video-icon.png';
import iconImage06 from './images-sections/icons/photography-icon.png';
import iconImage07 from './images-sections/icons/podcasting-icon.png';
import iconImage08 from './images-sections/icons/social-icon.png';
import iconImage09 from './images-sections/icons/it-icon.png';
import {Link} from 'react-router-dom';



export default class Services extends Component {
    state = {};
    render() {
        let style = {
            backgroundImage: `url(${servicesBgImage})`
        }
        return (
            <div className="services_section" id="services">                
                <div style={style} className='services_section_background'>
                    
                    <div className="services_container">  
                    <div className='services_page_title'>BUSINESS SERVICES</div>
                    <div className='services_page_subtitle'>Here are some services we provide:</div>  
                        <div className='services_box'>
                            <img className='icon_image' alt='' src={iconImage01} />
                            <div className="service_box_title">BRAND STRATEGY</div>                        
                            <div className='service_box_content'>Our 10-Step Plan will help define and refine your brand into something extraordinary and memorable.</div>
                        </div>                        
                        <div className='services_box' id='webdev'>
                            <img className='icon_image' alt='' src={iconImage03} />
                            <div className="service_box_title">WEBSITE DEVELOPMENT</div>                        
                            <div className='service_box_content'>Building in WordPress, SquareSpace, Wix or a fully-custom React.JS website, we'll craft a site that fully embodies your brand.</div>
                        </div>                 
                        <div className='services_box' id='vidprod'>
                            <img className='icon_image' alt='' src={iconImage05} />
                            <div className="service_box_title">VIDEO PRODUCTION</div>                        
                            <div className='service_box_content'>A professional video will make the most lasting impact and reach the most people.</div>
                        </div>
                        <div className='services_box' id='photography'>
                            <img className='icon_image' alt='' src={iconImage06} />
                            <div className="service_box_title">PHOTOGRAPHY</div>                       
                            <div className='service_box_content'>High quality photos, catchy image procurement and proper positioning enhances brand appeal.
                                <br /><Link to="/photography" className="service_box_button"> PHOTO GALLERY</Link>
                            </div>
                        </div>
                        <div className='services_box' id='podcasting'>
                            <img className='icon_image' alt='' src={iconImage07} />
                            <div className="service_box_title">PODCAST CONSULTATION</div>                       
                            <div className='service_box_content'>Podcasting has broken through, we'll show you how to setup your own podcast properly.</div>
                        </div>
                        <div className='services_box' id='advisory'>
                            <img className='icon_image' alt='' src={iconImage02} />
                            <div className="service_box_title">STARTUP ADVISORY</div>                       
                            <div className='service_box_content'>Get a CTO's experience for getting your startup from idea to launch: Strategy, Business Planning, Financials and Investment Pitching.</div>
                        </div>
                        <div className='services_box' id='socialmgmt'>
                            <img className='icon_image' alt='' src={iconImage08} />
                            <div className="service_box_title">SOCIAL MEDIA MANAGEMENT</div>                        
                            <div className='service_box_content'>More than likely, you are using Social Media incorrectly. We'll manage it properly for you.</div>
                        </div>
                        <div className='services_box' id='digmarketing'>
                            <img className='icon_image' alt='' src={iconImage04} />
                            <div className="service_box_title">DIGITAL MARKETING</div>                        
                            <div className='service_box_content'>We'll build a markting campaign using business and performance analytics.</div>
                        </div>
                        <div className='services_box' id='itservices'>
                            <img className='icon_image' alt='' src={iconImage09} />
                            <div className="service_box_title">IT SERVICES</div>                       
                            <div className='service_box_content'>Management, deployment, maintenance, equipment procurement, vendor management, and personnel.</div>
                        </div>                        
                        
                    </div>
                </div>
            </div>
        )
    }
}
