import React, { Component } from 'react';
import './message3.css';

export default class Closing extends Component {
    render() {
        return (
            <div className="message3_section" id="closing">
                <div className="message3_content_container">  
                    <div className="message3_first_line">We provide smart</div>
                    <div className="message3_second_line">SOLUTIONS</div>
                    <div className="message3_third_line">for diverse projects</div>                    
                </div>   
            </div>  
        )
    }
}
