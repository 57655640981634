import React, { Component } from 'react';
import './main.css';
import mainBgImage from './images-sections/backgrounds/bg-a.jpg';
import logo from './images-sections/logos/xegotech-wings-logo.png';

export default class Main extends Component {
    state = {};
    render() {
        let style = {
            backgroundImage: `url(${mainBgImage})`
        }
        return (
            <div className="main_section" id="main">
                <div style={style} className='main_section_background'>
                    <div className="main_section_content_container">
                        <div className="main_logo_space">
                            <img className='main_logo_img' alt='' src={logo} />
                        </div> 
                        <div className="main_first_line">TECHNICAL and CREATIVE SERVICES THAT <span className='main_second_line'>FLY</span></div>                      
                    </div>                  
                </div>
            </div>
        )
    }
}

