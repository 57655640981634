import React, { Component } from 'react';
import portfolioBgImage from './images-sections/backgrounds/bg-image9.jpg';
import './portfolio.css';
import portfolioImage01 from './images-sections/portfolio-01.jpg';
import portfolioImage02 from './images-sections/portfolio-02.jpg';
import portfolioImage03 from './images-sections/portfolio-03.jpg';
import portfolioImage04 from './images-sections/portfolio-04.jpg';

export default class Portfolio extends Component {
    state = {};
    render() {
        let style = {
            backgroundImage: `url(${portfolioBgImage})`
        }
        return (
            <div style={style} className="portfolio_section" id="portfolio">                          
                    <div className='portfolio_container'>
                        <div className='portfolio_section_title'>BUSINESS CLIENTS</div>
                        <div className='portfolio_section_subtitle'>Some of our satisfied customers:</div>
                        <div className='portfolio_box'>
                            <div className='portfolio_box_title'>INNOVATIVE COMPANIES</div>
                            <div className='portfolio_box_content'>Crafting and refining brands for dynamic companies such as tech, financial, construction and real estate brokers</div>
                            <img className='portfolio_box_image' alt='' src={portfolioImage01} />
                        </div>
                        <div className='portfolio_box'>
                            <div className='portfolio_box_title'>BUSINESS PROFESSIONALS</div>
                            <div className='portfolio_box_content'>Developing websites, marketing and presentational material for local business owners, real estate agents and financial advisors</div>
                            <img className='portfolio_box_image' alt='' src={portfolioImage02} />
                        </div>
                        <div className='portfolio_box'>
                            <div className='portfolio_box_title'>CHARITY / SPECIAL EVENTS</div>
                            <div className='portfolio_box_content'>Creating digital marketing campaigns for charity, business, networking or social events</div>
                            <img className='portfolio_box_image' alt='' src={portfolioImage03} />
                        </div>
                        <div className='portfolio_box'>
                            <div className='portfolio_box_title'>SOCIAL MEDIA INFLUENCERS</div>
                            <div className='portfolio_box_content'>Producing photography &amp; video for influencers on YouTube, models on Instagram and business personalities on Facebook</div>
                            <img className='portfolio_box_image' alt='' src={portfolioImage04} />
                        </div>
                    </div>
                </div>
  
        )
    }
}
