import React, { Component } from 'react';
import './message4.css';

export default class Closing extends Component {
    render() {
        return (
            <div className="message4_section" id="closing">
                <div className="message4_content_container">  
                    <div className="message4_first_line">Technical and Creative</div>
                    <div className="message4_second_line">SOLUTIONS</div>
                    <div className="message4_third_line">for discerning clients</div>                    
                </div>   
            </div>  
        )
    }
}
