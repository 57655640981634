import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from "../sections/Footer";
import brandBgImage from './images-sections/backgrounds/bg-image3.jpg';
import './brand.css';

export default class Brand extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render() {
        let style = {
            backgroundImage: `url(${brandBgImage})`
        }
        return (
            <div className="brand_page" id="brand">
                <div style={style} className='brand_page_background'>
                <div className="brand_page_container">                                                        
                    <div className='brand_content'>                    
                            <div className='brand_headline'>BRAND COMES FIRST</div>  
                            <div className='brand_text'>
                                <p>
                                    Everyone thinks the business idea comes first. This may be true most of the time, but your genesis 
                                    story serves as just a single component among others in a brand's overall architecture. 
                                    Many components must be defined or refined in order to keep a brand uniform 
                                    across all your marketing messages and mediums. Executing your brand strategy
                                    while you are growing your business remains paramount in a world full of noise and short 
                                    attention spans.
                                </p>
                                <p> 
                                    Marketing without a well developed brand, only exacerbates the public's instinct to
                                    overlook or ignore your message altogether. Even worse, unwhittingly, your well intentioned message and 
                                    costly marketing campaign may cause irreparable damage leaving the impression that your business is also unrefined 
                                    and mediocre. A well crafted and developed brand provides a framework for communicating messages to 
                                    control these malformed perceptions. 
                                </p>
                                <p>
                                    Xego leads you through the pain staking process of crafting your brand components and constructing
                                    a brand architecture properly. In order for your brand to serves as the soul of your business, we must first
                                    bring your brand to life. We possess the skills and experience of breathing new life into your brand.   
                                </p>
                            </div>
                            <Link to='/' className='brand_home_button'>BACK HOME</Link>
                        
                    </div>                                                
                    </div>                                         
                <div className='footer_section'>
                    <Footer />
                </div>   
                </div>          
            </div>  
            
              
          
        )
    }
}
