import React, { Component } from 'react';
import contactBgImage from './images-sections/backgrounds/bg-d.jpg';
import './contact.css';

export default class Contact extends Component {
    state = {};
    render() {
        let style = {
            backgroundImage: `url(${contactBgImage})`
        }
        return (
            <div className="contact_section" id="contact">
                <div style={style} className='contact_section_background'>
                    <div className="contact_section_content_container">
                        <div className='contact_headline'>CONTACT US</div>
                        <div className='contact_content'>
                            <p>
                                Do you have a question, need an estimate or require consultation?
                            </p>
                            <ul>
                                <li>Brand Building &amp; Strategy</li>
                                <li>Website Development</li>
                                <li>Video Production</li>
                                <li>Photography</li>
                                <li>Podcast Consultation</li>
                                <li>Startup Advisory</li>                                                                
                                <li>Social Media &amp; Content Management</li>
                                <li>Digital Marketing</li>       
                                <li>IT Services</li>
                            </ul>
                            <p>
                                Call us for a quick consultation:
                            </p>
                            <h3>
                                US: (646) 403-7500
                            </h3>
                            <p>
                                Or, send us a message by clicking the button below, < br/>then completing and submitting the following form:
                            </p>
                            <a href="/form.html"><button className='contact_button'>SEND MESSAGE</button></a>
                        </div>
                                             
                    </div>                        
                </div>                  
            </div> 
        )
    }
}
