import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../sections/Footer';
import './photography.css';
import PhotoImage001 from './images-sections/photogallery/pg01.jpg';
import PhotoImage002 from './images-sections/photogallery/pg02.jpg';
import PhotoImage003 from './images-sections/photogallery/pg03.jpg';
import PhotoImage004 from './images-sections/photogallery/pg04.jpg';
import PhotoImage005 from './images-sections/photogallery/pg05.jpg';
import PhotoImage006 from './images-sections/photogallery/pg06.jpg';
import PhotoImage007 from './images-sections/photogallery/pg07.jpg';
import PhotoImage008 from './images-sections/photogallery/pg08.jpg';
import PhotoImage009 from './images-sections/photogallery/pg09.jpg';
import PhotoImage010 from './images-sections/photogallery/pg10.jpg';
import PhotoImage011 from './images-sections/photogallery/pg11.jpg';
import PhotoImage012 from './images-sections/photogallery/pg12.jpg';
import PhotoImage013 from './images-sections/photogallery/pg13.jpg';
import PhotoImage014 from './images-sections/photogallery/pg14.jpg';
import PhotoImage015 from './images-sections/photogallery/pg15.jpg';
import PhotoImage016 from './images-sections/photogallery/pg16.jpg';

import PhotoImage018 from './images-sections/photogallery/pg18.jpg';
import PhotoImage019 from './images-sections/photogallery/pg19.jpg';
import PhotoImage020 from './images-sections/photogallery/pg20.jpg';
import PhotoImage021 from './images-sections/photogallery/pg21.jpg';
import PhotoImage022 from './images-sections/photogallery/pg22.jpg';
import PhotoImage023 from './images-sections/photogallery/pg23.jpg';
import PhotoImage024 from './images-sections/photogallery/pg24.jpg';
import PhotoImage025 from './images-sections/photogallery/pg25.jpg';
import PhotoImage026 from './images-sections/photogallery/pg26.jpg';
import PhotoImage027 from './images-sections/photogallery/pg27.jpg';
import PhotoImage028 from './images-sections/photogallery/pg28.jpg';
import PhotoImage029 from './images-sections/photogallery/pg29.jpg';
import PhotoImage030 from './images-sections/photogallery/pg30.jpg';
import PhotoImage031 from './images-sections/photogallery/pg31.jpg';
import PhotoImage032 from './images-sections/photogallery/pg32.jpg';





export default class Photography extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    //   ----------- this disables right click ---------- //
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
          });
      }
    
    render() {        
        return (
            <div className='pg_grid_section'>
                <div className='pg_grid_header'>PHOTO  GALLERY</div>
                <div className='pg_grid_secondline'>By SOLITO REYES II</div>
                <div className='pg_grid_container'>
                    <div className='pg_grid_box p-1'>
                        <img className='pg_grid_image' alt='' src={PhotoImage001} />
                    </div>
                    <div className='pg_grid_box p-2'>
                        <img className='pg_grid_image' alt='' src={PhotoImage002} />
                    </div>
                    <div className='pg_grid_box p-3'>
                        <img className='pg_grid_image' alt='' src={PhotoImage003} />
                    </div>
                    <div className='pg_grid_box p-4'>
                        <img className='pg_grid_image' alt='' src={PhotoImage004} />
                    </div>
                    <div className='pg_grid_box p-5'>
                        <img className='pg_grid_image' alt='' src={PhotoImage005} />
                    </div>
                     <div className='pg_grid_box p-6'>
                        <img className='pg_grid_image' alt='' src={PhotoImage006} />
                    </div>
                    <div className='pg_grid_box p-7'>
                        <img className='pg_grid_image' alt='' src={PhotoImage007} />
                    </div>                   
                    <div className='pg_grid_box p-8'>
                        <img className='pg_grid_image' alt='' src={PhotoImage008} />
                    </div>
                    <div className='pg_grid_box p-9'>
                        <img className='pg_grid_image' alt='' src={PhotoImage009} />
                    </div>
                    <div className='pg_grid_box p-10'>
                        <img className='pg_grid_image' alt='' src={PhotoImage010} />
                    </div>
                    <div className='pg_grid_box p-11'>
                        <img className='pg_grid_image' alt='' src={PhotoImage011} />
                    </div>
                    <div className='pg_grid_box p-12'>
                        <img className='pg_grid_image' alt='' src={PhotoImage012} />
                    </div>
                    <div className='pg_grid_box p-13'>
                        <img className='pg_grid_image' alt='' src={PhotoImage013} />
                    </div>
                    <div className='pg_grid_box p-14'>
                        <img className='pg_grid_image' alt='' src={PhotoImage014} />
                    </div>
                    <div className='pg_grid_box p-15'>
                        <img className='pg_grid_image' alt='' src={PhotoImage015} />
                    </div>
                    <div className='pg_grid_box p-16'>
                        <img className='pg_grid_image' alt='' src={PhotoImage016} />
                    </div>
                    
                    <div className='pg_grid_box p-18'>
                        <img className='pg_grid_image' alt='' src={PhotoImage018} />
                    </div>
                    <div className='pg_grid_box p-19'>
                        <img className='pg_grid_image' alt='' src={PhotoImage019} />
                    </div>
                    <div className='pg_grid_box p-20'>
                        <img className='pg_grid_image' alt='' src={PhotoImage020} />
                    </div>
                    <div className='pg_grid_box p-21'>
                        <img className='pg_grid_image' alt='' src={PhotoImage021} />
                    </div>
                    <div className='pg_grid_box p-22'>
                        <img className='pg_grid_image' alt='' src={PhotoImage022} />
                    </div>
                    <div className='pg_grid_box p-23'>
                        <img className='pg_grid_image' alt='' src={PhotoImage023} />
                    </div>
                    <div className='pg_grid_box p-24'>
                        <img className='pg_grid_image' alt='' src={PhotoImage024} />
                    </div>
                    <div className='pg_grid_box p-25'>
                        <img className='pg_grid_image' alt='' src={PhotoImage025} />
                    </div>
                    <div className='pg_grid_box p-26'>
                        <img className='pg_grid_image' alt='' src={PhotoImage026} />
                    </div>
                    <div className='pg_grid_box p-27'>
                        <img className='pg_grid_image' alt='' src={PhotoImage027} />
                    </div>   
                    <div className='pg_grid_box p-28'>
                        <img className='pg_grid_image' alt='' src={PhotoImage028} />
                    </div> 
                    <div className='pg_grid_box p-29'>
                        <img className='pg_grid_image' alt='' src={PhotoImage029} />
                    </div>      
                    <div className='pg_grid_box p-30'>
                        <img className='pg_grid_image' alt='' src={PhotoImage030} />
                    </div>  
                    <div className='pg_grid_box p-31'>
                        <img className='pg_grid_image' alt='' src={PhotoImage031} />
                    </div>    
                    <div className='pg_grid_box p-32'>
                        <img className='pg_grid_image' alt='' src={PhotoImage032} />
                    </div>         
                </div>

                <div className='pg_grid_homebtn_space'>
                    <div className='pg_grid_quote'>
                        "ENGINEERING AND ART IS ONE<br /> AND THE SAME!"
                    </div>
                    <div className='pg_grid_quote_source'>
                        - Solito Reyes II
                    </div>
                    <Link to='/' className='pg_grid_home_button'>HOME</Link>
                </div>
                <div className='footer_style'>
                    <Footer/>
                </div>
            </div>
        )
    }
}
