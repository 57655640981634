import React, { Component } from 'react';
import PhotoImage001 from './images-sections/photogallery/pg01.jpg';
import PhotoImage002 from './images-sections/photogallery/pg02.jpg';
import PhotoImage003 from './images-sections/photogallery/pg03.jpg';
import PhotoImage004 from './images-sections/photogallery/pg04.jpg';
import PhotoImage005 from './images-sections/photogallery/pg05.jpg';
import PhotoImage006 from './images-sections/photogallery/pg06.jpg';
import PhotoImage007 from './images-sections/photogallery/pg07.jpg';
import PhotoImage008 from './images-sections/photogallery/pg08.jpg';
import PhotoImage009 from './images-sections/photogallery/pg09.jpg';
import PhotoImage010 from './images-sections/photogallery/pg10.jpg';
import PhotoImage011 from './images-sections/photogallery/pg11.jpg';
import PhotoImage012 from './images-sections/photogallery/pg12.jpg';
import PhotoImage013 from './images-sections/photogallery/pg13.jpg';
import PhotoImage014 from './images-sections/photogallery/pg14.jpg';
import PhotoImage015 from './images-sections/photogallery/pg15.jpg';
import PhotoImage016 from './images-sections/photogallery/pg16.jpg';
import PhotoImage017 from './images-sections/photogallery/pg17.jpg';
import PhotoImage018 from './images-sections/photogallery/pg18.jpg';
import PhotoImage019 from './images-sections/photogallery/pg19.jpg';
import PhotoImage020 from './images-sections/photogallery/pg20.jpg';
import PhotoImage021 from './images-sections/photogallery/pg21.jpg';
import PhotoImage022 from './images-sections/photogallery/pg22.jpg';
import PhotoImage023 from './images-sections/photogallery/pg23.jpg';
import PhotoImage024 from './images-sections/photogallery/pg24.jpg';
import PhotoImage025 from './images-sections/photogallery/pg25.jpg';
import PhotoImage026 from './images-sections/photogallery/pg26.jpg';
import PhotoImage027 from './images-sections/photogallery/pg27.jpg';
import './photographyflex.css';

export default class Photographyflex extends Component {
    render() {
        return (
            <div className='photogallery_section'>
                <div className='photogallery_header'>PHOTO GALLERY</div>
                <div className='photogallery_secondline'>By Solito Reyes II</div>
                <div className='photogallery_container'>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage001} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage002} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage003} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage004} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage005} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage006} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage007} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage008} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage009} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage010} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage011} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage012} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage013} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage014} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage015} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage016} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage017} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage018} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage019} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage020} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage021} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage022} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage023} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage024} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage025} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage026} />
                    </div>
                    <div className='photo_box'>
                        <img className='photo_image' alt='' src={PhotoImage027} />
                    </div>
                </div>
            </div>
        )
    }
}
