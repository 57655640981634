import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import aboutBgImage from './images-sections/backgrounds/bg-b.jpg';
import './about.css';
import './styles-common.css';

export default class About extends Component {
    state = {};
    render() {
        let style = {
            backgroundImage: `url(${aboutBgImage})`
        }
        return (
            <div className="about_section" id="about">
                <div style={style} className='about_section_background'>
                    <div className="about_section_content_container">
                        <div className='about_headline'>BRAND <br />COMES <br />FIRST</div>                                       
                        <div className='about_content'>                              
                                Everyone thinks the business idea comes first. This may be true, but your genesis 
                                story serves as just a single component among others in a brand's overall architecture. 
                                Many components must be... 
                            <div className='about_btn_space'>
                            <Link to='/brand' className='about_button'>READ MORE...</Link>   
                            </div>          
                        </div>                        
                    </div>                  
                </div>
            </div>
        )
    }
}
