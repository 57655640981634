import React, { Component } from 'react';
import './message2.css';

export default class Salespitch extends Component {
    render() {
        return (
            <div className="message2_section" id="marketing">
                <div className="message2_content_container">                
                    <div className="message2_first_line">Let's work together to</div>
                    <div className="message2_second_line">REJUVENATE</div>
                    <div className="message2_third_line">your brand's personality!</div>                    
                              
                </div>                
            </div>
        )
    }
}
